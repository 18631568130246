.overlay-content {
    display: flex;
    width: 100%;
    left: 0;
    top: 0;
    align-items: center;
    height: 100%;
    justify-content: center;
    position: fixed;
    z-index: 999999;
    background: rgba(255, 255, 255, 0.8);
    flex-direction: column;
}

.overlay-content .wrapper {
    display: inherit;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo {
    width: 35px;
    height: 40px;
    position: absolute;
    top: 20px;
    left: 22px;
}

.MuiCircularProgress-colorPrimary {
    color: #d05e5a !important;
}